<template>
  <div
    id="app-wrapper"
    class="app-wrapper"
    :class="{
      'overflow-hidden': frontendSettingsStore.state.hideScroll,
      'app-wrapper--full-height': !isLarge,
    }"
    :style="computedStylesForAppWrapper"
  >
    <header
      class="header"
      :class="{ 'header--hide': !frontendSettingsStore.state.header }"
    >
      <div
        v-show="frontendSettingsStore.state.header"
        ref="header"
        class="header__fixed"
        :class="{
          'header__fixed--dark': isHeaderFixedDark,
        }"
      >
        <div>
          <AccessManagerBanner
            v-if="
              profileStore.isAccessManager && frontendSettingsStore.state.header
            "
            @banner-mounted="updateHeaderHeight"
          />
        </div>
        <Component :is="headerComponent" />
      </div>
      <BannerGenerator
        v-if="appStore.isAppLoaded"
        class="banner-generator"
        :style="computedStylesForBannerGenerator"
        @banner-height="bannerHeight = $event"
      />
      <AiSearchBreadcrumbs />
    </header>
    <div
      class="h-page"
      :class="[
        {
          'h-page--no-top': !frontendSettingsStore.state.header,
        },
        `${$route.name}-page`,
      ]"
    >
      <div :style="computedStylesForAppWrapperMain" class="app-wrapper__main">
        <NavigationWrapper
          :computed-header-and-banner-height="computedHeaderAndBannerHeight"
        />
        <div
          id="scrollable-content"
          class="h-content flex-grow-1"
          :class="{
            'h-content--fullscreen': frontendSettingsStore.state.fullscreen,
            'h-content--fullscreen-mobile':
              frontendSettingsStore.state.mobileFullscreen,
            'h-content--sidemenu':
              frontendSettingsStore.isNavigationVisible && !isLarge,
            'h-content--scrollable': !isLarge,
          }"
        >
          <ReferralsTopBanner />

          <div
            class="d-flex h-container flex-grow-1"
            :class="[
              {
                'h-container--fullscreen':
                  frontendSettingsStore.state.fullscreen,
              },
            ]"
          >
            <HAsideLeft
              v-if="frontendSettingsStore.state.asideComponent"
              class="h-mr-40"
            />
            <div class="flex-grow-1 w-max-100 d-flex flex-column">
              <BlackFridayBanner />
              <HSubheader
                v-if="!isFromIframe"
                v-show="frontendSettingsStore.state.showSubheader"
                :hide-back-word="hideBackWord"
              >
                <Component :is="subheaderComponent" v-if="subheaderComponent" />
                <template v-else>
                  {{ routeName }}
                </template>
                <template #tools>
                  <VncTerminal
                    v-if="frontendSettingsStore.state.showBrowserTerminal"
                  />
                  <CopyToClipboard
                    v-else-if="frontendSettingsStore.state.showSshLogin"
                  >
                    {{ frontendSettingsStore.state.showSshLogin }}
                  </CopyToClipboard>
                  <Component :is="subheaderImage" v-if="subheaderImage" />
                  <div id="overhead-button" />

                  <CPanelWordPressAction v-if="isCpanelWordPress" />
                </template>
              </HSubheader>

              <div
                :class="{
                  'h-mt-16':
                    !frontendSettingsStore.state.fullscreen ||
                    !frontendSettingsStore.state.fullHeight,
                  'h-100':
                    frontendSettingsStore.state.fullscreen ||
                    frontendSettingsStore.state.fullHeight,
                }"
              >
                <div
                  v-show="
                    frontendSettingsStore.state.pageLoading ||
                    appStore.isRedirectLoading
                  "
                  class="page-loading h-container flex-grow-1"
                  :class="computedClasses"
                >
                  <HLoader />
                </div>

                <ErrorBoundaryPage>
                  <slot name="body" />
                </ErrorBoundaryPage>
              </div>
              <div
                v-if="
                  !frontendSettingsStore.state.fullscreen &&
                  !frontendSettingsStore.state.fullHeight
                "
                class="h-mb-68"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isObject } from 'lodash';

import AiSearchBreadcrumbs from '@/components/AiSearchBreadcrumbs.vue';
import HAsideLeft from '@/components/AsideLeft/HAsideLeft';
import BlackFridayBanner from '@/components/BlackFriday/BlackFridayBanner';
import CPanelWordPressAction from '@/components/CPanel/CPanelWordPressAction.vue';
import CopyToClipboard from '@/components/CopyToClipboard';
import ErrorBoundaryPage from '@/components/ErrorBoundary/ErrorBoundaryPage.vue';
import HSubheader from '@/components/HSubheader';
import AccessManagerBanner from '@/components/Header/Banners/AccessManager';
import BannerGenerator from '@/components/Header/Banners/BannerGenerator';
import DomainLinkBanner from '@/components/Header/Banners/DomainLinkBanner';
import HTemplatePreviewHeader from '@/components/Headers/HTemplatePreviewHeader.vue';
import DataLoader from '@/components/Loaders/DataLoader';
import HLoader from '@/components/Loaders/HLoader';
import NavigationWrapper from '@/components/NavigationWrapper.vue';
import HPortlet from '@/components/Portlet/HPortlet';
import ReferralsTopBanner from '@/components/Referrals/ReferralsTopBanner.vue';
import Search from '@/components/Search';
import VncTerminal from '@/components/Servers/Overview/VncTerminal';
import { useMenu, useSaleCampaign, useScreen } from '@/composables';
import {
  useAppStore,
  useFrontendSettingsStore,
  useProfileStore,
} from '@/stores';
import { Hotjar, Route } from '@/types';
import { replaceParameters, toUnicode } from '@/utils/helpers';
import { hotjar } from '@/utils/services/Hotjar';
import { getWindowQueryParams } from '@/utils/services/windowService';

export default {
  components: {
    AiSearchBreadcrumbs,
    VncTerminal,
    HLoader,
    HPortlet,
    DataLoader,
    DomainLinkBanner,
    HTemplatePreviewHeader,
    HAsideLeft,
    Search,
    AccessManagerBanner,
    HSubheader,
    BannerGenerator,
    CopyToClipboard,
    BlackFridayBanner,
    CPanelWordPressAction,
    ErrorBoundaryPage,
    ReferralsTopBanner,
    NavigationWrapper,
  },
  name: 'Wrapper',

  data() {
    return {
      bannerHeight: 0,
      headerHeight: 0,
      sidemenuHeight: 0,
      showAutoLoginBanner: null,
      headerHeightObserver: null,
    };
  },
  setup() {
    const { isEligibleForBlackFridayDarkMode } = useSaleCampaign();
    const { isLarge } = useScreen();
    const appStore = useAppStore();
    const frontendSettingsStore = useFrontendSettingsStore();
    const profileStore = useProfileStore();
    const { headerComponent } = useMenu();

    return {
      headerComponent,
      frontendSettingsStore,
      profileStore,
      isEligibleForBlackFridayDarkMode,
      isLarge,
      appStore,
    };
  },
  mounted() {
    this.observeHeaderHeight();
    this.setIsFromIframe();

    if (this.frontendSettingsStore.state.header) {
      hotjar(Hotjar.TopBar.UPDATED_TOPBAR, true);
    }
  },
  computed: {
    computedStylesForAppWrapper() {
      return {
        // This is used to set the the top position of hp-sidemenu mobile top navigation
        '--marginless-header-height': `${this.headerHeight}px`,
        // This is used for calculating max height of FullWIdthBanner when expanded
        '--header-and-sidemenu-height': `${
          this.headerHeight + this.sidemenuHeight
        }px`,
      };
    },
    computedStylesForAppWrapperMain() {
      return {
        'margin-top': this.computedHeaderAndBannerHeight,
      };
    },
    computedHeaderAndBannerHeight() {
      return `${this.bannerHeight + this.headerHeight}px`;
    },
    computedStylesForBannerGenerator() {
      return {
        top: `${this.headerHeight}px`,
      };
    },
    isHeaderFixedDark() {
      return (
        this.isEligibleForBlackFridayDarkMode &&
        this.frontendSettingsStore.state.header === true
      );
    },
    subheaderImage() {
      return this.$route.meta?.subheaderImage || null;
    },
    subheaderComponent() {
      return this.$route.meta?.subHeaderComponent || null;
    },
    hideBackWord() {
      return this.$route.meta?.hideBackWord;
    },
    routeName() {
      if (this.$route.meta?.hideTitle) return;

      const subHeaderTitle = this.$route.meta?.subHeaderTitle;
      const parameters = cloneDeep(this.routeParams);

      try {
        parameters.domain = toUnicode(parameters.domain) ?? '';
      } catch (e) {
        // ignore
      }

      if (isObject(subHeaderTitle)) {
        return (
          (subHeaderTitle?.translate
            ? this.$t(subHeaderTitle.title, parameters)
            : toUnicode(replaceParameters(subHeaderTitle.title, parameters))) ||
          this.$route.meta?.title
        );
      }

      return this.$t(subHeaderTitle || this.$route.meta?.title, parameters);
    },
    routeLoaded() {
      return this.$route.meta.loaded !== undefined
        ? this.$route.meta.loaded
        : true;
    },
    computedClasses() {
      return {
        'pt-5': !this.frontendSettingsStore.state.fullscreen,
        'h-container--fullscreen': this.frontendSettingsStore.state.fullscreen,
        'h-container--fullscreen-mobile':
          this.frontendSettingsStore.state.mobileFullscreen,
      };
    },
    routeParams() {
      return { ...this.$route.params, ...this.$redirect.params };
    },
    isCpanelWordPress() {
      return this.$route.name === Route.CPanel.WORDPRESS;
    },
  },
  methods: {
    setIsFromIframe() {
      this.isFromIframe = getWindowQueryParams()?.['is-from-iframe'] === 'true';
    },
    observeHeaderHeight() {
      this.headerHeightObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const newHeight = entry.contentRect.height;
          this.headerHeight = newHeight;
        }
      });
      this.headerHeightObserver.observe(this.$refs.header);
    },
  },
  watch: {
    '$route.name'() {
      this.setIsFromIframe();
    },
  },
  beforeUnmount() {
    this.headerHeightObserver?.unobserve(this.$refs.header);
    this.headerHeight = 0;
  },
};
</script>
<style lang="scss">
@import '../sass/layout/header/_header';
@import '../sass/layout/h-page';
@import '../sass/layout/h-content';
@import '../sass/layout/reponsiveness-utilities';

.page-loading {
  @media (min-width: 768px) {
    display: flex;
  }
}

.app-wrapper {
  min-height: 100%;

  &--full-height {
    height: 100vh;
    overflow: hidden;
  }

  &__main {
    display: flex;
    flex-grow: 1;
    height: 100%;
    flex-direction: row;

    .h-content--sidemenu {
      margin-left: var(--hmenu-width);
    }

    .h-content--scrollable {
      height: calc(100vh - v-bind(computedHeaderAndBannerHeight));
      overflow-y: auto;
    }
  }

  @media (max-width: 992px) {
    &__main {
      flex-direction: column;
    }
  }
}

.banner-generator {
  position: absolute;
  width: 100%;
  z-index: var(--z-index-2);
}

@media (max-width: 992px) {
  #side-menu {
    z-index: var(--z-index-4);
  }
}
</style>
