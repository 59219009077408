import { computed } from 'vue';
import { useRoute, type RouteRecordName } from 'vue-router';

import {
  useBrands,
  useExperiment,
  useGlobals,
  useProfile,
} from '@/composables';
import { useLanguages } from '@/composables/useLanguages';
import { useProfileStore, useReferralStore } from '@/stores';
import { Country, Experiment, HIcon, Referral, Route } from '@/types';
import { isCurrentTimeWithinDates } from '@/utils/helpers';

const REFERRAL_ROUTES = [
  Route.Referral.REFFER_AND_EARN,
  Route.Referral.MY_REFERRALS,
] as RouteRecordName[];

export const useReferralsPromotion = () => {
  const { t } = useGlobals();
  const route = useRoute();
  const { accountDaysRegistered } = useProfile();
  const { isFromAnotherBrand } = useBrands();
  const { country } = useProfileStore();
  const referralsStore = useReferralStore();
  const profileStore = useProfileStore();
  const { isExperimentActive } = useExperiment(
    Experiment.ID.WEBPRO_REFERRALS_BANNERS_COPY,
  );
  const { isCurrentLanguageEnglish } = useLanguages();

  const promotions = computed(() => {
    if (!referralsStore.rewardRate) {
      return [];
    }

    const isCustomPromotionActive =
      referralsStore.rewardRate !== Referral.Rates.INITIAL_REFERRAL_RATE;
    const customPromotionCommission =
      referralsStore.rewardRate === Referral.Rates.COMMISSION_40
        ? Referral.CommissionAmount.USD_450
        : Referral.CommissionAmount.USD_300;

    // Priority is defined by the order of the array.
    // If there are multiple candidates by date, the first one will be active.
    return [
      ...(isCustomPromotionActive
        ? [
            {
              id: 'wordcamp',
              referralDiscountRate: Referral.Rates.INITIAL_REFERRAL_RATE,
              commissionRate: referralsStore.rewardRate,
              title: t('v2.referrals.wordcamp.offer', {
                commissionInDollars: customPromotionCommission,
                friendDiscountPercentage: `${
                  Referral.Rates.INITIAL_REFERRAL_RATE * 100
                }%`,
              }),
              placements: [],
              startDate: '2024-09-01T00:00:00Z',
              endDate: '2025-03-31T23:59:59Z',
              key: Referral.PromotionalTopBannerKey.WORDCAMP,
              promotionType: Referral.LimitedTimeOfferCampaignTypeKey.ALL_PLANS,
              backgroundColor: 'dark',
              textColor: 'white',
              noTimer: true,
              isCloseButtonVisible: false,
              isButtonVisible: false,
              homepageButtonText: t(
                'v2.refer.and.earn.up.to.commissionInDollars.usd',
                {
                  commissionInDollars: customPromotionCommission,
                },
              ),
              homepageButtonSubtitle: t(
                'v2.your.friends.will.get.friendCommissionPercentage.off.too.its.win.win.exclamation',
                {
                  friendCommissionPercentage: `${
                    Referral.Rates.DISCOUNT_RATE * 100
                  }%`,
                },
              ),
              isHomepageButtonAnimated: false,
              topBarLinkText:
                isCurrentLanguageEnglish() &&
                t('v2.refer.and.earn.commissionInDollars.usd', {
                  commissionInDollars: customPromotionCommission,
                }),
              isTopBarLinkHiddenInHomepage: false,
              isTopBarLinkAnimated: true,
              icon: {
                name: HIcon.ICON_BADGE_DISCOUNT,
                color: 'teal',
              },
            },
          ]
        : []),
      {
        id: 'Q3C5',
        referralDiscountRate: Referral.Rates.INITIAL_REFERRAL_RATE,
        commissionRate: Referral.Rates.SPECIAL_OFFER_COMMISSION,
        title: t('v2.referrals.Q3C5.title', {
          openTag: '<strong>',
          closeTag: '</strong>',
        }),
        placements: [
          Route.Websites.WEBSITE_DASHBOARD,
          Route.Websites.BUILDER_DASHBOARD,
          Route.Base.SERVERS,
        ],
        startDate: '2024-09-16T09:00:00Z',
        endDate: '2024-09-28T23:59:59Z',
        key: Referral.PromotionalTopBannerKey.CLOUD_VPS,
        promotionType: Referral.LimitedTimeOfferCampaignTypeKey.CLOUD_VPS,
        backgroundColor: 'primary-light',
        textColor: 'meteorite-dark',
        noTimer: true,
        buttonColor: 'danger',
        closeButtonColor: 'primary-charts',
        isCloseButtonVisible: !isInReferralsRoute.value,
        isButtonVisible: !isInReferralsRoute.value,
        homepageButtonText: t(
          'v2.refer.and.earn.up.to.commissionInDollars.usd',
          {
            commissionInDollars: 400,
          },
        ),
        homepageButtonSubtitle: t(
          'v2.your.friends.will.get.friendCommissionPercentage.off.too.its.win.win.exclamation',
          {
            friendCommissionPercentage: `${
              Referral.Rates.REDUCED_DISCOUNT_RATE * 100
            }%`,
          },
        ),
        isHomepageButtonAnimated: false,
        topBarLinkText:
          isCurrentLanguageEnglish() &&
          t('v2.refer.and.earn.commissionInDollars.usd', {
            commissionInDollars: 400,
          }),
        isTopBarLinkHiddenInHomepage: false,
        isTopBarLinkAnimated: true,
        icon: {
          name: HIcon.ICON_BADGE_DISCOUNT,
          color: 'primary-dark',
        },
      },
      {
        id: 'Q3C4',
        commissionRate: Referral.Rates.INCREASED_COMMISSION,
        title: t('v2.referrals.Q3C4.title', {
          openTag: '<strong>',
          closeTag: '</strong>',
        }),
        placements: [],
        startDate: '2024-09-02T09:00:00Z',
        endDate: '2024-09-15T23:59:59Z',
        key: Referral.PromotionalTopBannerKey.CLOUD_VPS_Q3C4,
        promotionType: Referral.LimitedTimeOfferCampaignTypeKey.CLOUD_VPS,
        backgroundColor: 'primary-light',
        textColor: 'meteorite-dark',
        noTimer: true,
        buttonColor: 'primary-dark',
        closeButtonColor: 'primary-charts',
        isCloseButtonVisible: !isInReferralsRoute.value,
        isButtonVisible: !isInReferralsRoute.value,
        homepageButtonText: t(
          'v2.refer.and.earn.up.to.commissionInDollars.usd',
          {
            commissionInDollars: 300,
          },
        ),
        homepageButtonSubtitle: t(
          'v2.your.friends.will.get.friendCommissionPercentage.off.too.its.win.win.exclamation',
          {
            friendCommissionPercentage: `${
              Referral.Rates.DISCOUNT_RATE * 100
            }%`,
          },
        ),
        isHomepageButtonAnimated: false,
        topBarLinkText:
          isCurrentLanguageEnglish() &&
          t('v2.refer.and.earn.commissionInDollars.usd', {
            commissionInDollars: 300,
          }),
        isTopBarLinkHiddenInHomepage: false,
        isTopBarLinkAnimated: true,
      },
      {
        id: 'Q3C3',
        commissionRate: Referral.Rates.SPECIAL_OFFER_COMMISSION,
        title: t(
          isExperimentActive.value
            ? "We've paid out 1.4M to referral members so far! Get up to {openTag}400 USD{closeTag} for each friend you  refer to Cloud & VPS plans."
            : 'Earn up to {openTag}400 USD{closeTag} for each friend you refer to a Cloud or VPS plan by September 1.',
          {
            openTag: '<strong>',
            closeTag: '</strong>',
          },
        ),
        placements: [
          Route.Websites.WEBSITE_DASHBOARD,
          Route.Websites.BUILDER_DASHBOARD,
          Route.Base.SERVERS,
        ],
        startDate: '2024-08-19T09:00:00Z',
        endDate: '2024-09-01T23:59:59Z',
        key: Referral.PromotionalTopBannerKey.CLOUD_VPS,
        promotionType: Referral.LimitedTimeOfferCampaignTypeKey.CLOUD_VPS,
        backgroundColor: 'meteorite-light',
        textColor: 'primary-dark',
        noTimer: true,
        buttonColor: 'primary-dark',
        closeButtonColor: 'primary-charts',
        isCloseButtonVisible: !isInReferralsRoute.value,
        isButtonVisible: !isInReferralsRoute.value,
        homepageButtonText: t(
          'v2.refer.and.earn.up.to.commissionInDollars.usd',
          {
            commissionInDollars: Referral.CommissionAmount.USD_400,
          },
        ),
        homepageButtonSubtitle: t(
          'v2.your.friends.will.get.friendCommissionPercentage.off.too.its.win.win.exclamation',
          {
            friendCommissionPercentage: `${
              Referral.Rates.DISCOUNT_RATE * 100
            }%`,
          },
        ),
        isHomepageButtonAnimated: true,
        topBarLinkText:
          isCurrentLanguageEnglish() &&
          t('v2.refer.and.earn.commissionInDollars.usd', {
            commissionInDollars: Referral.CommissionAmount.USD_400,
          }),
        isTopBarLinkHiddenInHomepage: true,
        isTopBarLinkAnimated: false,
        icon: {
          name: HIcon.ICON_BADGE_DISCOUNT,
          color: 'primary-dark',
        },
      },
      {
        id: 'Q3C2',
        commissionRate: Referral.Rates.SPECIAL_OFFER_COMMISSION,
        title: t(
          'Earn up to {openTag}400 USD{closeTag} per referral. Your referral will get a 20% discount too.',
          {
            openTag: '<strong>',
            closeTag: '</strong>',
          },
        ),
        placements: [
          Route.Websites.WEBSITE_DASHBOARD,
          Route.Websites.BUILDER_DASHBOARD,
          Route.Base.SERVERS,
        ],
        startDate: '2024-08-05T09:00:00Z',
        endDate: '2024-08-18T23:59:59Z',
        key: Referral.PromotionalTopBannerKey.ALL_PLANS,
        promotionType: Referral.LimitedTimeOfferCampaignTypeKey.ALL_PLANS,
        backgroundColor: 'meteorite-dark',
        textColor: 'white',
        timerVariant: 'white',
        icon: {
          name: HIcon.ICON_BADGE_DISCOUNT,
          color: 'white',
        },
        closeButtonColor: 'white',
      },
    ];
  });

  const isInReferralsRoute = computed(() =>
    REFERRAL_ROUTES.includes(route.name as RouteRecordName),
  );

  const isBannerVisibleInRoute = (
    promotion: (typeof promotions.value)[number],
  ) => {
    if (isInReferralsRoute.value) {
      return true;
    }

    const placements = promotion.placements as RouteRecordName[];

    return placements.includes(route.name as RouteRecordName);
  };

  const activePromotion = computed(() => {
    const isNonEligibleBrand =
      isFromAnotherBrand.value || country === Country.Code.RU;

    const isWordCampPromotionActive =
      activePromotionByTime.value?.key ===
      Referral.PromotionalTopBannerKey.WORDCAMP;

    if (
      (accountDaysRegistered.value < 7 && !isWordCampPromotionActive) ||
      isNonEligibleBrand ||
      referralsStore.isMemberDisabled ||
      profileStore.isAccessManager
    ) {
      return null;
    }

    const promotion =
      activePromotionByTime.value &&
      isBannerVisibleInRoute(activePromotionByTime.value)
        ? activePromotionByTime.value
        : undefined;

    if (!promotion) {
      return null;
    }

    return promotion;
  });

  const activePromotionByTime = computed(() =>
    promotions.value.find((promotion) =>
      isCurrentTimeWithinDates({
        startsAt: promotion.startDate,
        endsAt: promotion.endDate,
      }),
    ),
  );

  const currentReferralDiscountRate = computed(() => {
    const promotion = activePromotion.value;

    return (
      promotion?.referralDiscountRate || Referral.Rates.INITIAL_REFERRAL_RATE
    );
  });

  return {
    activePromotion,
    isInReferralsRoute,
    activePromotionByTime,
    currentReferralDiscountRate,
  };
};
