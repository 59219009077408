<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import HMenuItem from '@/components/HMenuV2/HMenuItem.vue';
import HSideMenuV2 from '@/components/HSideMenuV2.vue';
import {
  useCookies,
  useGlobals,
  useHpanelHostingTabV2Experiment,
  useResourceFilters,
  useResourceMapper,
} from '@/composables';
import { useAppStore, useHPanelControlsStore, useProfileStore } from '@/stores';
import { useHDomainResourceStore } from '@/stores/domain/hDomainResourceStore';
import {
  ACCESS_LEVEL,
  AmplitudeEvent,
  AmplitudeLocation,
  Cookie,
  RedirectMutations,
  Route,
  type NavigationMenuItem,
} from '@/types';

type Props = {
  computedHeaderAndBannerHeight: string;
};

defineProps<Props>();

const { t } = useGlobals();

const store = useStore();
const appStore = useAppStore();
const profileStore = useProfileStore();
const hpanelControlsStore = useHPanelControlsStore();

const { hasManageableHosting } = useResourceFilters();
const { resourcesWithSubscriptions, activeDeveloperToolsResources } =
  useResourceMapper();
const { isHpanelHostingTabV2ExperimentActive } =
  useHpanelHostingTabV2Experiment();
const { transferInList } = useHDomainResourceStore();
const { isCookieValueTruthyNumber: isAddonsVisible } = useCookies(
  Cookie.ADDONS_ENABLED,
);
const route = useRoute();

const isHostingVisible = computed(
  () =>
    appStore.isAppLoaded &&
    !isHpanelHostingTabV2ExperimentActive.value &&
    hasManageableHosting.value,
);
const hasBillingAccess = computed(() => {
  const accessLevel = profileStore.access?.level;

  return !accessLevel || accessLevel === ACCESS_LEVEL.PURCHASE;
});

const isBillingVisible = computed(() => hasBillingAccess.value);

const hostingRoute = computed((): NavigationMenuItem[] => {
  if (isHostingVisible.value) {
    return [
      {
        to: { name: Route.Base.HOSTING_V2 },
        slug: 'hosting',
        title: t('Hosting'),
        icon: 'icon-servers-16',
      },
    ];
  }

  return [];
});

const addonsRoute = computed((): NavigationMenuItem[] => {
  if (isAddonsVisible.value) {
    const isInDashboard = route.matched.some(
      ({ name }) => name === Route.DeveloperTools.DASHBOARD,
    );
    const currentRouteName = route.name ?? undefined;

    const dashboardRouteName = isInDashboard
      ? currentRouteName
      : Route.DeveloperTools.DASHBOARD;

    return [
      {
        to: {
          name: activeDeveloperToolsResources.value.length
            ? dashboardRouteName
            : Route.DeveloperTools.PURCHASE,
        },
        slug: 'addons',
        title: t('WordPress add-ons'),
        icon: 'icon-blocks-plus-16',
        activeSubroutes: Object.values(Route.DeveloperTools),
      },
    ];
  }

  return [];
});

const billingRoute = computed((): NavigationMenuItem[] => {
  if (isBillingVisible.value) {
    return [
      {
        slug: 'billing',
        title: t('Billing'),
        notificationCount: getNotificationCountValue(dueInvoicesCount.value),
        icon: 'icon-billing-16',
        children: [
          {
            to: { name: Route.HBilling.SUBSCRIPTIONS },
            slug: 'subscriptions',
            title: t('Subscriptions'),
            activeSubroutes: [
              Route.HBilling.SUBSCRIPTIONS,
              Route.HBilling.SUBSCRIPTION_DETAILS,
            ],
          },
          {
            to: { name: Route.HBilling.PAYMENT_HISTORY_PAID },
            slug: 'payment-history',
            title: t('Payment History'),
            activeSubroutes: [
              Route.HBilling.REFUND_HISTORY,
              Route.HBilling.PAYMENT_HISTORY_PAID,
              Route.HBilling.REFUND_HISTORY_DETAILS,
            ],
          },
          {
            to: { name: Route.HBilling.PAYMENT_METHODS },
            slug: 'payment-methods',
            title: t('Payment Methods'),
          },
        ],
      },
    ];
  }

  return [];
});

const dueInvoicesCount = computed(() =>
  resourcesWithSubscriptions.value.reduce(
    (acc, resource) => acc + resource.subscription?.dueInvoicesCount || 0,
    0,
  ),
);

const getNotificationCountValue = (count: number) => (count > 9 ? '9+' : count);

const navigationRoutes = computed((): NavigationMenuItem[] => [
  {
    to: {
      name: Route.Base.HOME,
    },
    slug: 'home',
    title: t('Home'),
    icon: 'icon-home-16',
    action() {
      store.commit(RedirectMutations.SET_REDIRECT, {
        amplitudeLocation: AmplitudeLocation.Action.HOME_BUTTON,
        amplitudeCreationPath: AmplitudeLocation.Action.HOME_BUTTON,
      });
    },
  },
  {
    to: { name: Route.Base.WEBSITES },
    slug: 'websites',
    title: t('Websites'),
    icon: 'icon-browser-window-16',
  },
  ...hostingRoute.value,
  {
    slug: 'domains',
    title: t('Domains'),
    icon: 'icon-globe-16',
    children: [
      {
        to: { name: Route.Domain.MY_DOMAINS },
        slug: 'domain-portfolio',
        title: t('Domain portfolio'),
        activeSubroutes: [Route.Domain.REGISTER_DOMAIN_RESOURCE],
      },
      {
        slug: 'new-domain',
        title: t('Get a New Domain'),
        to: { name: Route.Domain.DOMAIN_CHECKER },
        amplitude: {
          eventName: AmplitudeEvent.Domain.CHECKER_ENTERED,
        },
      },
      {
        slug: 'domain-transfers',
        title: t('Transfers'),
        activeSubroutes: [Route.Domain.TRANSFER_DOMAIN],
        to: { name: Route.Domain.TRANSFERS },
        notificationCount: transferInList?.length,
      },
    ],
  },
  {
    to: { name: Route.Base.EMAILS },
    slug: 'emails',
    title: t('Emails'),
    icon: 'icon-envelope-16',
    activeSubroutes: [
      Route.Marketplace.EMAIL,
      Route.Email.TITAN_CHOOSE_PLAN,
      Route.Email.CHOOSE_PLAN,
      Route.Email.TITAN_DASHBOARD,
      Route.Email.TITAN_UPGRADE_PLAN,
      Route.Email.CREATE_ACCOUNT,
      Route.Email.UPGRADE_PLAN,
      Route.Email.GOOGLE_WORKSPACE_MANAGEMENT,
    ],
  },
  {
    to: { name: Route.Base.SERVERS },
    slug: 'vps',
    title: t('VPS'),
    icon: 'icon-database-16',
  },
  ...billingRoute.value,
  ...addonsRoute.value,
  {
    slug: 'marketplace',
    title: t('Marketplace'),
    icon: 'icon-shop-16',
    children: [
      {
        slug: 'marketplace-products',
        title: t('All products'),
        to: { name: Route.Base.MARKETPLACE },
      },
      {
        slug: 'Hosting',
        title: t('Hosting'),
        to: { name: Route.Marketplace.HOSTING },
        activeSubroutes: [Route.Base.BUY_HOSTING, Route.Base.BUY_VPS],
      },
      {
        slug: 'marketplace-domains',
        title: t('Domains'),
        to: { name: Route.Marketplace.DOMAIN },
        activeSubroutes: [],
      },
      {
        slug: 'marketplace-emails',
        title: t('Emails'),
        to: { name: Route.Marketplace.EMAIL },
        activeSubroutes: [
          Route.Marketplace.EMAIL,
          Route.Email.TITAN_CHOOSE_PLAN,
          Route.Email.CHOOSE_PLAN,
        ],
      },
      {
        slug: 'marketplace-services',
        title: t('Services'),
        to: { name: Route.Marketplace.SERVICES },
        activeSubroutes: [Route.Marketplace.SERVICES],
      },
      {
        slug: 'marketplace-other',
        title: t('Other'),
        to: { name: Route.Marketplace.OTHER },
      },
    ],
  },
]);
</script>

<template>
  <div class="navigation">
    <div class="navigation__mock-width" />
    <HSideMenuV2
      class="navigation__level-1"
      :class="{
        'navigation__level-1--visible':
          hpanelControlsStore.navigationLevel === 1,
      }"
      :has-feedback="false"
      :navigation-items="navigationRoutes"
    >
      <template v-if="hpanelControlsStore.hasSecondLevelNavigation" #header>
        <HMenuItem
          :navigation-item="{
            title: t('Overview'),
            icon: 'icon-blocks-16',
            slug: 'overview',
          }"
          append-icon="icon-ic-chevron-small-right-16"
          @click="hpanelControlsStore.setNavigationLevel(2)"
        />
      </template>
    </HSideMenuV2>

    <div
      class="navigation__level-2"
      :class="{
        'navigation__level-2--visible':
          hpanelControlsStore.navigationLevel === 2,
      }"
    >
      <div id="side-menu" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.navigation {
  position: fixed;
  height: calc(100vh - v-bind(computedHeaderAndBannerHeight));
  z-index: var(--z-index-5);

  @media (max-width: 992px) {
    height: 100%;
  }

  &__mock-width {
    width: var(--hmenu-width);
  }

  &__level-1,
  &__level-2 {
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.5s cubic-bezier(0.32, 0.72, 0, 1);
    border-right: 1px solid var(--gray-border);
    height: 100%;
    border-top: 1px solid var(--gray-border);

    & > * {
      height: 100%;
    }
  }

  &__level-1 {
    z-index: 1;
    transform: translateX(-100%);

    &--visible {
      transform: translateX(0);
    }
  }

  &__level-2 {
    z-index: 2;
    transform: translateX(-100%);

    &--visible {
      transform: translateX(0);
    }
  }
}
</style>
