import type { NavigationGuard } from 'vue-router';

import { useFrontendSettingsStore, useSubscriptionsStore } from '@/stores';
import { useHDomainResourceStore } from '@/stores/domain/hDomainResourceStore';
import { Route } from '@/types';
import { retryAsyncFunction } from '@/utils/helpers';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { i18n } from '@/utils/services/i18nService';

const domainGuard: NavigationGuard = async (to, from, next) => {
  const hDomainResourceStore = useHDomainResourceStore();
  const domain = to.params.domain as string;
  const subscriptionId = to.params.subscriptionId as string;
  const routeName = to.name as string;
  const frontendSettingsStore = useFrontendSettingsStore();

  if (!hDomainResourceStore.isHDomainResourcesLoaded) {
    frontendSettingsStore.setState('pageLoading', true);
    await hDomainResourceStore.fetchHDomainsResources();
    frontendSettingsStore.setState('pageLoading', false);
  }

  frontendSettingsStore.setState('pageLoading', true);
  const requiredResourcesList = await Promise.all([
    checkDoesHDomainResourceExist({
      domain,
      routeName,
    }),
    checkDoesSubscriptionExist({
      domain,
      subscriptionId,
    }),
  ]);
  frontendSettingsStore.setState('pageLoading', false);

  const isAllRequiredResourcesExist = requiredResourcesList.every(Boolean);

  if (!isAllRequiredResourcesExist) {
    toastr.e(i18n.t('Order not found'));

    const name = isTransferRoute(routeName)
      ? Route.Domain.TRANSFERS
      : Route.Domain.MY_DOMAINS;

    return next({ name });
  }

  return next();
};

const checkDoesSubscriptionExist = async (data: {
  domain: string;
  subscriptionId?: string;
}) => {
  const subscriptionStore = useSubscriptionsStore();

  if (!!subscriptionStore.getDomainSubscriptionByProperty(data)) {
    return true;
  }

  await subscriptionStore.fetchAllSubscriptions();

  return !!subscriptionStore.getDomainSubscriptionByProperty(data);
};

const checkDoesHDomainResourceExist = async (data: {
  domain: string;
  routeName: string;
}) => {
  const { fetchHDomainsResources } = useHDomainResourceStore();

  if (getIsHDomainResourceExist(data)) {
    return true;
  }

  await retryAsyncFunction({
    functionToRetry: fetchHDomainsResources,
    getIsRetryNeeded: () => !getIsHDomainResourceExist(data),
    triesCount: 3,
    timeoutInMilliseconds: 2000,
  });

  return getIsHDomainResourceExist(data);
};

const getIsHDomainResourceExist = ({
  domain,
  routeName,
}: {
  domain: string;
  routeName: string;
}) => {
  const { getHDomainResourceByDomain, getHDomainTransferResourceByDomain } =
    useHDomainResourceStore();

  return isTransferRoute(routeName)
    ? !!getHDomainTransferResourceByDomain
    : !!getHDomainResourceByDomain(domain);
};

const isTransferRoute = (routeName: string) =>
  routeName === Route.Domain.TRANSFER_DOMAIN_MANAGEMENT;

export default domainGuard;
