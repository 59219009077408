import { defineStore } from 'pinia';
import { ref } from 'vue';

import { hBillingRepo } from '@/repositories';
import { useResourcesStore } from '@/stores';
import { useHDomainResourceStore } from '@/stores/domain/hDomainResourceStore';
import type {
  BillingSubscription,
  ISubscriptionOrderWithSubscriptionId,
  RequestConfig,
  BillingInvoice,
} from '@/types';
import { BillingOrder, HBilling } from '@/types';
import {
  BUSINESS_AND_CLOUD_PLANS,
  BUSINESS_OR_ABOVE_PLANS,
} from '@/types/models/billing/hResourcesModels';
import { QUERY_CACHE_KEYS } from '@/types/models/queryServiceModels';
import { adjustEmailPlanTitle } from '@/utils/helpers/emailsHelper';
import { fetchQuery } from '@/utils/services/queryService';

export const useSubscriptionsStore = defineStore('subscriptionsStore', () => {
  const subscriptions = ref<BillingSubscription[]>([]);
  const isSubscriptionsLoading = ref(false);
  const subscriptionOrder = ref<ISubscriptionOrderWithSubscriptionId>();
  const paymentDueInvoices = ref<BillingInvoice[]>([]);
  const isPaymentDueInvoicesLoaded = ref(false);
  const isLoaded = ref(false);

  const getSubscriptionById = (id: string) =>
    subscriptions.value.find((subscription) => subscription.id === id);

  const isSubscriptionPlanInList = (
    id: string,
    planList: typeof BUSINESS_AND_CLOUD_PLANS | typeof BUSINESS_OR_ABOVE_PLANS,
  ) => {
    const plan = getSubscriptionById(id)?.cfSubscriptionParams?.plan;
    const availablePlan = planList.map((plan) => plan as string);

    return plan ? availablePlan.includes(plan) : false;
  };

  const getIsSubscriptionHostingPlanBusinessOrAboveAll = (id: string) =>
    isSubscriptionPlanInList(id, BUSINESS_OR_ABOVE_PLANS);

  const getIsSubscriptionHostingPlanBusinessOrAbove = (id: string) =>
    isSubscriptionPlanInList(id, BUSINESS_AND_CLOUD_PLANS);

  const hasSubscriptionPendingInvoice = (subscriptionId: string) =>
    !!paymentDueInvoices.value.filter(
      (subscription) => subscription.id === subscriptionId,
    ).length;

  const fetchSubscriptionOrder = async (subscriptionId: string) => {
    const [{ data }, error] = await hBillingRepo.getSubscriptionOrders(
      subscriptionId,
      {
        statuses: [BillingOrder.Status.COMPLETED],
        types: [BillingOrder.Type.SUBSCRIPTION_ORDER],
      },
    );

    if (error || !data?.length) return;

    const [managedSubscriptionOrder] = data;

    subscriptionOrder.value = { ...managedSubscriptionOrder, subscriptionId };
  };

  const getDomainTransferSubscriptionByDomain = (domain: string) => {
    const subscription = subscriptions.value.find(
      (subscription: BillingSubscription) => {
        const { cfSubscriptionParams, metadata, items } = subscription;

        const subscriptionDomain =
          cfSubscriptionParams?.domain || metadata?.domain;

        const isSubscriptionDomainTransfer = items?.find(({ itemPriceId }) =>
          itemPriceId?.includes('domaintransfer'),
        );

        return subscriptionDomain === domain && isSubscriptionDomainTransfer;
      },
    );

    return subscription;
  };

  const getSubscriptionByHResourceId = (hResourceId: string) => {
    const resourceStore = useResourcesStore();
    const hResource = resourceStore.getResourceById(Number(hResourceId));
    const subscriptionID = hResource?.chargebeeSubscriptionId;

    if (!subscriptionID) {
      return null;
    }

    return getSubscriptionById(subscriptionID);
  };

  const getSubscriptionByDomain = (domain: string) => {
    const { getResourceById, fetchResources } = useResourcesStore();
    const { getHDomainResourceByDomain } = useHDomainResourceStore();
    const hDomainResource = getHDomainResourceByDomain(domain);

    if (!hDomainResource) {
      return null;
    }

    const hResourceId = hDomainResource?.additionalDetails?.hresourceId;
    let hResource = getResourceById(Number(hResourceId));

    if (!hResource) {
      fetchResources();

      hResource = getResourceById(Number(hResourceId));
    }

    const subscriptionID = hResource?.chargebeeSubscriptionId;

    if (!subscriptionID) {
      return null;
    }

    return getSubscriptionById(subscriptionID);
  };

  const getDomainSubscriptionByProperty = ({
    hResourceId,
    domain,
    subscriptionId,
  }: {
    hResourceId?: string;
    domain?: string;
    subscriptionId?: string;
  }) => {
    if (subscriptionId) {
      return getSubscriptionById(subscriptionId);
    }

    if (hResourceId) {
      return getSubscriptionByHResourceId(hResourceId);
    }

    if (!domain) {
      return null;
    }

    return getSubscriptionByDomain(domain);
  };

  const updateSingleSubscription = (subscription: BillingSubscription) => {
    const subscriptionIndex = subscriptions.value.findIndex(
      ({ id }) => id === subscription.id,
    );

    if (subscriptionIndex < 0) {
      return;
    }

    subscriptions.value[subscriptionIndex] = subscription;
  };

  const fetchAllSubscriptions = async (requestConfig?: RequestConfig) => {
    isSubscriptionsLoading.value = true;
    let pageOffset: string | undefined | null;
    const MAX_SUBSCRIPTIONS_PER_PAGE = 100;
    const tempSubscriptions: BillingSubscription[] = [];

    while (pageOffset !== null) {
      const config = {
        ...requestConfig,
        params: {
          perPage: MAX_SUBSCRIPTIONS_PER_PAGE,
          pageOffset,
        },
      };

      const [response, error] = await fetchQuery({
        queryKey: [
          QUERY_CACHE_KEYS.GET_SUBSCRIPTIONS_WITH_SCHEDULED_CHANGES,
          config.params.perPage,
          config.params.pageOffset,
        ],
        queryFn: async () =>
          hBillingRepo.getSubscriptionsWithScheduledChanges(config),
        staleTime: 500,
        overrideCache: config.overrideCache,
      });

      if (error) {
        isSubscriptionsLoading.value = false;

        return [response, error];
      }

      tempSubscriptions.push(...response.data);
      pageOffset = response.nextOffset;

      if (pageOffset === null) {
        subscriptions.value = tempSubscriptions.map(adjustEmailPlanTitle);
      }
    }

    isSubscriptionsLoading.value = false;
    isLoaded.value = true;

    return [{ data: subscriptions.value }, null];
  };

  const fetchSubscriptionPaymentDueInvoices = async () => {
    const [{ data }, error] = await hBillingRepo.getInvoices({
      status: HBilling.InvoiceStatus.PAYMENT_DUE,
    });

    if (error) return;

    paymentDueInvoices.value = data;
    isPaymentDueInvoicesLoaded.value = true;
  };

  const fetchListOfAvailablePeriodsAndUpdateSubscription = async ({
    id,
    priceId,
  }: {
    id: string;
    priceId: string;
  }) => {
    const [{ data }, error] = await hBillingRepo.getItemByPrice(priceId);
    if (error) return;

    subscriptions.value = subscriptions.value.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          periods: data.prices,
        };
      }

      return item;
    });
  };

  return {
    subscriptions,
    isSubscriptionsLoading,
    subscriptionOrder,
    isLoaded,
    getSubscriptionById,
    getSubscriptionByDomain,
    fetchSubscriptionOrder,
    getIsSubscriptionHostingPlanBusinessOrAbove,
    fetchAllSubscriptions,
    getDomainTransferSubscriptionByDomain,
    getDomainSubscriptionByProperty,
    fetchSubscriptionPaymentDueInvoices,
    paymentDueInvoices,
    isPaymentDueInvoicesLoaded,
    hasSubscriptionPendingInvoice,
    updateSingleSubscription,
    getIsSubscriptionHostingPlanBusinessOrAboveAll,
    getSubscriptionByHResourceId,
    fetchListOfAvailablePeriodsAndUpdateSubscription,
  };
});
