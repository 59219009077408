import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { useCookies } from './useCookies';

import { useExperiment, useResources } from '@/composables';
import { ONE_MINUTE_IN_SECONDS } from '@/data/globalConstants';
import { websiteBuilderRepo } from '@/repositories';
import { useResourcesStore } from '@/stores';
import { useHDomainsStore } from '@/stores/domain/hDomainsStore';
import { Route, Cookie, SubscriptionResourceType, Experiment } from '@/types';
import { mapKeyValue } from '@/utils/helpers';
import { errorLogger } from '@/utils/services/errorLogging';

const REDIRECT_COOKIE_OPTIONS = { domain: process.env.VITE_COOKIE_DOMAIN };
const REGEX_IS_REDIRECT_URL_TRUSTED = /(\.|^)(hostinger|zyro).(com|io|dev)$/;
export const useRedirects = () => {
  const store = useStore();
  const router = useRouter();
  const route = useRoute();
  const { getMostRecentPendingResourceByType } = useResources();
  const resourcesStore = useResourcesStore();
  const { setIsDomainRegistrationPrompted } = useHDomainsStore();
  const { removeCookie, setCookie, cookie } = useCookies(
    Cookie.HPANEL_REDIRECT,
  );
  const { isExperimentActive: isReferralsRedirectExperimentActive } =
    useExperiment(Experiment.ID.HPANEL_REFERRALS_REDIRECT_TO_HOMEPAGE);

  const CUSTOM_REDIRECTS_MAP = {
    'onboarding-builder-prefill': () => {
      const resource = getMostRecentPendingResourceByType(
        SubscriptionResourceType.HOSTING,
      );

      if (!resource) return;

      // TODO: add scenario: SETUP_WEBSITE_BUILDER_PRESELECTED to the params and remove query after backend fixes the cookie assignment
      router.push({
        name: Route.OnboardingV2.BASE_PATH,
        params: {
          order_id: resource.chargebeeSubscriptionId,
        },
        query: {
          preselect: 1,
        },
      });
    },
    'onboarding-vps': () => {
      const resource = getMostRecentPendingResourceByType(
        SubscriptionResourceType.VIRTUAL_MACHINE,
      );

      if (!resource || route.query.purchase_flow) return;

      router.push({
        name: Route.VpsOnboarding.SERVER_ONBOARDING_START,
        params: { order_id: resource.chargebeeSubscriptionId },
      });
    },
    'onboarding-wordpress-prefill': () => {
      const resource = getMostRecentPendingResourceByType(
        SubscriptionResourceType.HOSTING,
      );

      if (!resource) return;

      // TODO: add scenario: SETUP_WORDPRESS_PRESELECTED to the params and remove query after backend fixes the cookie assignment
      router.push({
        name: Route.OnboardingV2.BASE_PATH,
        params: {
          order_id: resource.chargebeeSubscriptionId,
        },
        query: {
          preselect: 1,
        },
      });
    },
    'onboarding-hosting': () => {
      const resource = getMostRecentPendingResourceByType(
        SubscriptionResourceType.HOSTING,
      );

      if (!resource) return;

      // TODO: add scenario: SETUP_WORDPRESS_PRESELECTED to the params and remove query after backend fixes the cookie assignment
      router.push({
        name: Route.OnboardingV2.BASE_PATH,
        params: {
          order_id: resource.chargebeeSubscriptionId,
        },
        query: {
          preselect: 1,
        },
      });
    },
    'onboarding-hosting-referral': () => {
      if (isReferralsRedirectExperimentActive.value) {
        // Will be added with WP-983
        return;
      }

      const resource = getMostRecentPendingResourceByType(
        SubscriptionResourceType.HOSTING,
      );

      if (!resource) return;

      router.push({
        name: Route.Onboarding.START,
        params: {
          order_id: resource.chargebeeSubscriptionId,
        },
      });
    },
    'onboarding-cpanel': () => {
      const resource = getMostRecentPendingResourceByType(
        SubscriptionResourceType.CPANEL_HOSTING,
      );

      if (!resource) return;

      router.push({
        name: Route.Onboarding.START,
        params: {
          order_id: resource.chargebeeSubscriptionId,
        },
        query: {
          cpanel: 'true',
          planId: resource.referenceId,
        },
      });
    },
    'onboarding-email': () => {
      const resourceHostingerMail = getMostRecentPendingResourceByType(
        SubscriptionResourceType.EMAIL,
      );
      if (resourceHostingerMail) {
        router.push({
          name: Route.Email.EMAIL_ONBOARDING_START,
          params: {
            orderId: resourceHostingerMail.chargebeeSubscriptionId,
          },
        });

        return;
      }

      const resourceTitanMail = getMostRecentPendingResourceByType(
        SubscriptionResourceType.TITAN_MAIL,
      );

      if (resourceTitanMail) {
        router.push({
          name: Route.Email.TITAN_ONBOARDING_START,
          params: {
            orderId: resourceTitanMail.chargebeeSubscriptionId,
          },
        });
      }
    },
    'onboarding-email-google': () => {
      const resourceGWMail = getMostRecentPendingResourceByType(
        SubscriptionResourceType.GOOGLE_WORKSPACE,
      );

      if (resourceGWMail) {
        router.push({
          name: Route.Email.GOOGLE_WORKSPACE_SETUP,
          params: {
            orderId: resourceGWMail.referenceId,
          },
        });
      }
    },
    'onboarding-titanmail': () => {
      const resourceTitanMail = getMostRecentPendingResourceByType(
        SubscriptionResourceType.TITAN_MAIL,
      );

      if (resourceTitanMail) {
        router.push({
          name: Route.Email.TITAN_ONBOARDING_START,
          params: {
            orderId: resourceTitanMail.chargebeeSubscriptionId,
          },
        });
      }
    },
    'onboarding-domain': () => {
      setIsDomainRegistrationPrompted(true);

      const resource = getMostRecentPendingResourceByType(
        SubscriptionResourceType.DOMAIN,
      );

      if (!resource?.title) {
        return;
      }

      router.push({
        name: Route.Domain.REGISTER_DOMAIN_RESOURCE,
        params: {
          domain: resource.title,
        },
        query: {
          instant: '1',
        },
      });
    },
    default: undefined,
  };

  const isCookieRedirectNeeded =
    !!cookie.value && mapKeyValue(CUSTOM_REDIRECTS_MAP, cookie.value);

  const redirectTo = async (link: string) => {
    let url: URL;
    try {
      url = new URL(link);
    } catch {
      return false;
    }

    try {
      const isValidUrl = REGEX_IS_REDIRECT_URL_TRUSTED.test(url.hostname);
      if (!isValidUrl) {
        router.replace({ name: Route.Base.NOT_FOUND });

        return false;
      }
      store.commit('SET_REDIRECT', { showGlobalAppLoader: true });

      const [, error] = await websiteBuilderRepo.postSession();

      if (error) {
        throw new Error('Builder session create error');
      }

      window.location.assign(url);

      return true;
    } catch (error) {
      errorLogger.logError(new Error('redirectTo error', { cause: error }));
      router.replace({ name: Route.Base.NOT_FOUND });

      return false;
    }
  };

  const performCookieRedirect = async () => {
    if (!cookie.value) return false;
    store.commit('SET_REDIRECT', { showGlobalAppLoader: true });
    const redirectValue = cookie.value;
    removeCookie(REDIRECT_COOKIE_OPTIONS);

    const customRedirectFunction = mapKeyValue(
      CUSTOM_REDIRECTS_MAP,
      redirectValue,
    );

    if (!resourcesStore.resources.length) {
      await resourcesStore.fetchResources({
        requestConfig: {
          cache: ONE_MINUTE_IN_SECONDS,
          overrideCache: true,
        },
      });
    }

    if (customRedirectFunction) {
      await customRedirectFunction();

      store.commit('UNSET_REDIRECT');

      return true;
    }

    const redirectResult = await redirectTo(redirectValue);
    store.commit('UNSET_REDIRECT');

    return redirectResult;
  };

  const setRedirectFlow = (flow: keyof typeof CUSTOM_REDIRECTS_MAP) => {
    setCookie(flow, REDIRECT_COOKIE_OPTIONS);
  };

  return {
    performCookieRedirect,
    redirectTo,
    setRedirectFlow,
    isCookieRedirectNeeded,
  };
};
